






























import {Component} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {IResource} from '@simpli/vue-input/lib/IResource'
import VueMultiselect from 'vue-multiselect/src/Multiselect.vue'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'

type SortOption = {
  value: string | null
  networkIdentifier: string | null
} & IResource
@Component({
  components: {
    VueMultiselect,
  },
})
export default class FilterNetwork extends MixinScreenSize {
  options: SortOption[] = []
  optionSelected: SortOption | null = null

  collection = new BlockchainVersionCollection()

  handleFilterNetwork(option: SortOption) {
    this.$emit('filter', option.networkIdentifier)
  }

  async created() {
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())

    this.options = this.collection.items.map(item => {
      return {
        $id: item.$id,
        $tag: item.$tag,
        value: item.title,
        networkIdentifier: item.networkIdentifier,
      }
    })
  }
}
